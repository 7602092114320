<template>
  <div style="background-image: url('https://imagedelivery.net/HPdN1Z_Ube9zTuVFQK-tGg/66096b97-21ef-45e6-3246-81ddd53dd000/public'); min-height: 100vh; height: auto; background-size: cover;">
    <!-- <img src="/build/assets/img/brand/khit_thit.jpg" alt="" /> -->
    <div class="col-12 d-flex my-0">
      <img
        class="mx-auto pt-4"
        src="/logo.jpg"
        alt=""
        style="width: auto; height: 100px;"
      />
    </div>
    
    <div class="col-lg-7 col-12 mx-auto p-0 d-flex main-image-wrapper">
        <b-carousel
            id="carousel-1"
            :interval="5000"
            controls
            fade
            style="text-shadow: 1px 1px 2px #333; width: 100%; height: 100%; padding: 10px;"
            v-if="!threedData.isLive"
        >
            <b-carousel-slide
                img-src="https://imagedelivery.net/HPdN1Z_Ube9zTuVFQK-tGg/84db5796-5f91-4fea-bfe3-eca364975200/public"
            ></b-carousel-slide>
            <b-carousel-slide
                img-src="https://imagedelivery.net/HPdN1Z_Ube9zTuVFQK-tGg/7f18fd0a-e366-4cab-1712-e78d5344cd00/public"
            ></b-carousel-slide>
            <b-carousel-slide
                img-src="https://imagedelivery.net/HPdN1Z_Ube9zTuVFQK-tGg/a839013f-a606-4f7e-8844-a8234da22f00/public"
            ></b-carousel-slide>
        </b-carousel>

        <!-- {{ threedData }} -->

        <div class="col-12 p-2" v-else>
                <div
                    class="m-auto col-12 p-0 video-container"
                    v-html="threedData.embedded_link"
                    style=" width: 100%; height: 100%;"
                    v-if="threedData.isLive"
                ></div>
            </div>

    </div>

    <div style="padding-left: 10px; padding-right: 10px;">
      <NoticeBar
        class="col-lg-12 col-12 mx-auto notice-bar"
        scrollable
        :text="websiteInfo[0]?.noticeText"
      />
    </div>

    <div class="col-12 account-container-wrapper d-flex py-1 my-2 pl-5">
      <div class="col-lg-6 col-5 my-auto">
        <img :src="$store.state.authUser.gender === 'female' ? 'https://imagedelivery.net/HPdN1Z_Ube9zTuVFQK-tGg/1b5a569c-c421-446c-86a8-98da438fbf00/public' : 'https://imagedelivery.net/HPdN1Z_Ube9zTuVFQK-tGg/2bc293e0-e453-442c-3dcd-a3572fece800/public'" style="width: auto; height: 100px; border-radius: 20%;" alt="">
      </div>

      <div class="col-lg-6 col-7 p-0">
        <div class="pt-2">
          <div class="d-flex">
            <p class="css-17h40hp mb-1" style="width: 85px;"> 
                        {{
                            $store.state.language === "en"
                                ? "Username"
                                : "အမည်"
                        }}</p>
            <p class="css-eurmd1 mb-1" style="font-weight: 400;">: {{$store.state.authUser.name}} </p>
          </div>
          <div class="d-flex">
            <p class="css-17h40hp mb-1" style="width: 85px">
                        {{
                            $store.state.language === "en"
                                ? "Balance"
                                : "လက်ကျန်ငွေ"
                        }}</p>
            <p class="css-eurmd1 mb-1" style="font-weight: 400;">:
              <b>{{ Number($store.state.amount).toLocaleString() }}</b>
            {{ $store.state.language === 'en' ? 'MMK' : 'ကျပ်' }} </p>
          </div>
          <div class="d-flex">
            <p class="css-17h40hp mb-1" style="width: 85px">
                        {{
                            $store.state.language === "en"
                                ? "Phone"
                                : "ဖုန်းနံပါတ်"
                        }}</p>
            <p class="css-eurmd1 mb-1" style="font-weight: 400;">: {{ $store.state.authUser.phone }} </p>
          </div>
          <div class="d-flex" v-if="$store.state.authUser.commissioner">
            <p class="css-17h40hp mb-1 text-nowrap" style="width: 85px">
                        {{
                            $store.state.language === "en"
                                ? "Agent Referral"
                                : "အေးဂျင့်ကုဒ်"
                        }}</p>
            <p class="css-eurmd1 mb-1" style="font-weight: 400;">: {{ $store.state.authUser.commissioner }}</p>
          </div>
        </div>
      </div>
    </div>

    <div class="px-2" style="display: grid; grid-template-columns: 1fr 1fr; gap: 0px;">

      <!-- <div class="justify-content-center cards-container-mobile "> -->
      <div
        class="home-cards px-1"
        @click="redirectRoute('2d3d')"
        v-if="$store.state.disableJson.home['2d3d']"
      >
        <div
          class="card d-flex justify-content-center align-items-center mb-2 pt-2"
          style="width: 100%; height: auto; border-radius: 5px"
        >
          <div>
            <img
              src="https://imagedelivery.net/HPdN1Z_Ube9zTuVFQK-tGg/586644ed-cb27-4f98-645c-1e412dcdbe00/public"
              alt=""
              style="width: auto; height: 100px"
            />
          </div>
        </div>
      </div>

      <div
        class="home-cards px-1"
        @click="redirectRoute('football')"
        v-if="$store.state.disableJson.home['football']"
      >
        <div
          class="card d-flex justify-content-center align-items-center mb-2 pt-2"
          style="width: 100%; height: auto; border-radius: 5px"
        >
          <div>
            <img
              src="https://imagedelivery.net/HPdN1Z_Ube9zTuVFQK-tGg/ef4559c2-5c45-4c22-c1d1-3d6c8db8f100/public"
              alt=""
              style="width: auto; height: 100px"
            />
          </div>
        </div>
      </div>

      <div
        class="home-cards px-1"
        @click="redirectRoute('slots')"
        v-if="$store.state.disableJson.home['slot']"
      >
        <div
          class="card d-flex justify-content-center align-items-center mb-2 pt-2"
          style="width: 100%; height: auto; border-radius: 5px"
        >
          <div>
            <img
              src="https://imagedelivery.net/HPdN1Z_Ube9zTuVFQK-tGg/ea5d1a80-9fd9-41b4-034c-6fa5549d9300/public"
              alt=""
              style="width: auto; height: 100px"
            />
          </div>
        </div>
      </div>

      <div
        class="home-cards px-1"
        @click="redirectRoute('fishing')"
        v-if="$store.state.disableJson.home['fishing']"
      >
        <div
          class="card d-flex justify-content-center align-items-center mb-2 pt-2"
          style="width: 100%; height: auto; border-radius: 5px"
        >
          <div>
            <img
              src="https://imagedelivery.net/HPdN1Z_Ube9zTuVFQK-tGg/4b70ef43-c34b-42c5-294a-7f854e566d00/public"
              alt=""
              style="width: auto; height: 100px"
            />
          </div>
        </div>
      </div> 

      <div
        class="home-cards px-1"
        @click="redirectRoute('betslip-history')"
      >
        <div
          class="card d-flex justify-content-center align-items-center mb-2 pt-2"
          style="width: 100%; height: auto; border-radius: 5px"
        >
          <div>
            <img
              src="https://imagedelivery.net/HPdN1Z_Ube9zTuVFQK-tGg/138985b0-0686-4bee-151a-83d0e46d4d00/public"
              alt=""
              style="width: auto; height: 100px"
            />
          </div>
        </div>
      </div>

      <div
        class="home-cards px-1"
        @click="redirectRoute('history')"
        v-if="$store.state.disableJson.home['transactions']"
      >
        <div
          class="card d-flex justify-content-center align-items-center mb-2 pt-2"
          style="width: 100%; height: auto; border-radius: 5px"
        >
          <div>
            <img
              src="https://imagedelivery.net/HPdN1Z_Ube9zTuVFQK-tGg/f0416690-9d63-49c2-dc86-344437eda900/public"
              alt=""
              style="width: auto; height: 100px"
            />
          </div>
        </div>
      </div>


      <div
        class="home-cards px-1"
        @click="redirectRoute('deposit')"
        v-if="$store.state.disableJson.home['deposit']"
      >
        <div
          class="card d-flex justify-content-center align-items-center mb-2 pt-2"
          style="width: 100%; height: 100px; border-radius: 5px"
        >
          <div>
            <img
              src="https://imagedelivery.net/HPdN1Z_Ube9zTuVFQK-tGg/f4ff7a59-5aa8-438d-a098-fedd82ade500/public"
              alt=""
              style="width: auto; height: 50px"
            />
          </div>
          <div class="pt-1">
            <h4 class="text-white">
              {{
                $store.state.language === 'en'
                  ? 'Deposit'
                  : 'ငွေသွင်းရန်'
              }}
            </h4>
          </div>
        </div>
      </div>

      <div
        class="home-cards px-1"
        @click="redirectRoute('withdraw')"
        v-if="$store.state.disableJson.home['withdraw']"
      >
        <div
          class="card d-flex justify-content-center align-items-center mb-2 pt-2"
          style="width: 100%; height: 100px; border-radius: 5px"
        >
          <div>
            <img
              src="https://imagedelivery.net/HPdN1Z_Ube9zTuVFQK-tGg/672d51f3-bd7c-4b68-e536-cf216ac00200/public"
              alt=""
              style="width: auto; height: 50px"
            />
          </div>
          <div class="pt-1">
            <h4 class="text-white">
              {{
                $store.state.language === 'en'
                  ? 'Withdraw'
                  : 'ငွေထုတ်ရန်'
              }}
            </h4>
          </div>
        </div>
      </div>

      <div class="home-cards px-1" @click="redirectRoute('setting')" v-if="$store.state.disableJson.home['setting']">
        <div
          class="card d-flex justify-content-center align-items-center mb-2 pt-2"
          style="width: 100%; height: 100px; border-radius: 5px"
        >
          <div>
            <img
              src="https://imagedelivery.net/HPdN1Z_Ube9zTuVFQK-tGg/6492f8e0-8884-4053-6afc-36e0ccadda00/public"
              alt=""
              style="width: auto; height: 50px"
            />
          </div>
          <div class="pt-1">
            <h4 class="text-white">
              {{ $store.state.language === 'en' ? 'Setting' : 'ကိုယ်ရေးအချက်အလက်' }}
            </h4>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 d-flex justify-content-end px-0">
        <div
          class="col-4 home-cards mr-0 mt-4 mb-3"
          @click="logout()"
        >
          <div
            class="card d-flex flex-row justify-content-center align-items-center mb-2 py-2 px-4"
          >
              <img
                src="https://imagedelivery.net/HPdN1Z_Ube9zTuVFQK-tGg/ef0a422f-a24e-4642-4fc9-b5176795b700/public"
                alt=""
                style="width: 20px; height: 20px"
              />
            <div>
              <p class="css-38745a pl-2 text-nowrap" style="color: #ffffff; font-weight: 400;">Log out</p>
            </div>
          </div>
        </div>
    </div>
  </div>
</template>
<script>
import { NoticeBar, Dialog, Toast } from 'vant';
import { mapActions } from 'vuex';

export default {
  components: { NoticeBar },
  data() {
    return {
      twoDdata: {},
      twoDHistories: {},
      threeDHistories: {},
      threedData: {},
      websiteInfo: {},
    };
  },
  methods: {
    ...mapActions(['logoutUser', 'fetchUser']),
    logout() {
      Dialog.confirm({
        title:
          this.$store.state.language === 'en'
            ? 'Are you sure to log out?'
            : 'ထွက်မှာ သေချာပါသလား?',
        cancelButtonText:
          this.$store.state.language === 'en' ? 'Cancel' : 'မထွက်တော့ပါ',
        confirmButtonText:
          this.$store.state.language === 'en' ? 'Sure' : 'သေချာပြီ',
      })
        .then(async () => {
          await this.logoutUser();
          this.$router.push('/auth/login');
          Toast.success(
            this.$store.state.language === 'en' ? 'Logged out!' : 'ထွက်ပြီးပြီ'
          );
        })
        .catch(() => {});
    },
    async redirectRoute(path) {
      try {
        if (path === 'tip') {
          const text =
            this.$store.state.language === 'en'
              ? 'This feature is not support yet.'
              : 'ယခုအချိန်တွင် အသုံးပြုလို့မရနိုင်သေးပါ။';
          Dialog.alert({
            message: text,
            confirmButtonText:
              this.$store.state.language === 'en' ? 'Okay' : 'Okay',
          }).then(() => {
            // on close
          });
        } else {
          this.$router.push(path);
        }
      } catch (error) {
        console.log(error);
      }
    },
    threedDrawDateFormat(date) {
      return moment(date).format('ll');
    },
    async fetchTwoDdata() {
      try {
        const res = await axios.get('/2d/data');
        this.isLive = res.data.data[0].isLive;
      } catch (error) {
        console.log(error);
      }
    },
    async fetchWebsiteData() {
      try {
        const res = await axios.get('/website-infos');
        this.websiteInfo = res.data.data;
      } catch (error) {
        console.log(error);
      }
    },
    async fetchThreedDdata() {
      try {
        const res = await axios.get('/3d/data');
        this.threedData = res.data.data[0];
      } catch (error) {
        console.log(error);
      }
    },
    async fetchTwoThreeDHistories() {
      try {
        const twoDres = await axios.get('/twod_history');
        const threeDres = await axios.get('/threed_history');
        this.twoDHistories = twoDres.data.data;
        this.threeDHistories = threeDres.data.data;
      } catch (error) {
        console.log(error);
      }
    },
  },
  async mounted() {
    const authUser = await this.fetchUser();
    if (!authUser) {
      this.$router.push('/auth/login');
    }
    if (authUser && !authUser.is_verified) {
      this.$router.push('/auth/verify');
    }
    this.fetchWebsiteData();
    this.fetchUser();
    this.fetchThreedDdata();
  },
};
</script>
<style scoped>
.css-38745a {
    margin: 0px;
    line-height: 1.5;
    font-size: 12px;
    /* font-family: NotoSansMyanmar; */
    font-weight: 500;
}
.css-17h40hp {
    margin: 0px;
    line-height: 1.5;
    font-size: 12px;
    /* font-family: NotoSansMyanmar; */
    font-weight: 500 !important;
}
.card {
  cursor: pointer;
  border-radius: 10px !important;
}
.main-image-wrapper img {
  width: 100%;
}
.video-container {
  height: 500px;
  width: 100%;
}
.notice-bar {
  margin-top: -40px;
  z-index: 1;
  font-size: 20px;
  color: #000000;
  font-weight: 500;
  background: rgba(255, 242, 0, 0.7);
}
.twod-threed-cards {
  height: 200px;
}
.live-number {
  font-weight: bold;
  font-size: 25px;
}
.live-result {
  font-weight: bolder;
  font-size: 60px;
  animation-name: live-number-animation;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}
.not-live-text {
  font-weight: bold;
  font-size: 24px;
}
.cards-container {
  display: flex;
  flex-wrap: wrap;
}
.card-body {
  padding: 0px !important;
}
.cards-container-mobile {
  display: none;
}
@keyframes live-number-animation {
  from {
    color: white;
  }
  to {
    color: red;
  }
}

/* @media (max-width: 768px) { */
  .middle-container {
    padding-top: 0.1rem !important;
  }
  hr {
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .video-container {
    height: 300px;
    width: 100%;
  }
  .main-wrapper {
    padding: 7px !important;
  }
  .notice-bar {
    margin-top: 0px;
    height: 25px;
    z-index: 1;
    font-size: 12px;
    color: #02133E;
    background: white;
    border-radius: 7px;
  }
  .live-number {
    font-weight: bold;
    font-size: 20px;
  }
  .live-result {
    font-weight: bolder;
    font-size: 37px;
  }

  .cards-container {
    display: none;
  }
  .cards-container-mobile {
    padding: 0px !important;
    /* margin-top: 5px !important; */
    display: flex;
    flex-wrap: wrap;
  }
  .home-cards {
    padding-left: 10px;
    padding-right: 10px;
    background: transparent;
  }
  .card-body {
    padding: 0px !important;
  }
  .not-live-text {
    font-size: 18px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .account-container-wrapper {
    width: auto;
    height: auto;
    border-radius: 10px;
    padding-left: 4px;
    padding-right: 4px;
    margin: 10px;
    color: white;
    background-color: rgba(255,255,255,0.2) !important;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }
  .top-wrapper,
  .bottom-wrapper {
    padding: 10px;
    width: 100%;
    display: flex;
  }
  .top-right,
  .bottom-right {
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: end;
  }
  .top-left,
  .bottom-left {
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: start;
  }
/* } */
</style>
