<template>
    <div v-if="this.football.is_kyay_open" >
      <div class="bet-footer">
        <div style="background: #303030;" class="col-12 mx-auto py-3 my-0">
            <div class="d-flex justify-content-between">
              <div class="col-3 p-0 my-auto">
                <span class="text-white font-weight-500 text-uppercase">မောင်း : <span class="badge badge-danger">{{ this.getFBOrder.fb_matches.length }}</span> </span>
              </div>
                <div class="col-6 p-0 pt-1">
                    <!-- {{ $store.state.language === "en" ? "Amount" : "ငွေပမာဏ" }} -->
                    <div class="form-group mb-0">
                            <input
                                type="number"
                                ref="amount"
                                v-model="amount"
                                class="form-control amount"
                                placeholder="100.00 MMK"
                                style="height: 30px;"
                            />
                            <small class="text-danger">{{
                                  errors.amount
                              }}</small>
                    </div>
                </div>
                <div class="col-3 p-0 pl-2">
                    <button class="btn btn-success text-dark" style="width: 100%;"  @click="setToPreviewVoucher()">
                        <div>
                            {{
                                $store.state.language === "en"
                                    ? "Bet"
                                    : "ထိုးမည်"
                            }}
                        </div>
                    </button>
                </div>
            </div>
        </div>
      </div>
      
        <h1
            class="text-center my-2 mx-2 py-1"
            style="
                background: #02133E;
                color: white;
                border-radius: 5px;
                font-size: 15px
            "
        >
            {{
                $store.state.language === "en"
                    ? "Finished Matches"
                    : "ပွဲပြီး ရလဒ်များ"
            }}
            <i
                class="fas fa-long-arrow-alt-up"
                style="color: red"
                v-if="isHistory"
                @click="changeHistory"
            ></i>
            <i
                class="fas fa-long-arrow-alt-down"
                style="color: red"
                v-if="!isHistory"
                @click="changeHistory"
            ></i>
        </h1>
        <!-- <div
            v-for="(league, leagueIndex) in historyLeagues"
            :key="leagueIndex"
            v-show="isHistory"
            class="card pb-3"
        > -->
            <!-- <div>
                <h3 class="ml-2 mt-4">{{ league.name }}</h3>
            </div> -->
            <div class="p-1" v-if="isHistory">
                <div v-for="(match, index) in historyLeagues" :key="index">
                    <div v-if="match.kyayHistories.length === 0">
                        <FootballTeamCard :match="match" type="history" />
                    </div>
                    <div v-if="match.kyayHistories.length > 0">
                        <FootballTeamCard
                            :match="match"
                            type="history"
                            v-for="(history, index) in match.kyayHistories"
                            :key="index"
                            isKyayHistory
                            :history="history"
                            v-show="
                                history.type === 'maung' ||
                                history.type === 'both'
                            "
                        />
                        <FootballTeamCard :match="match" type="history" />
                    </div>
                </div>
            </div>
        <!-- </div> -->
        <h1
            class="text-center my-2 mx-2 py-1"
            style="
                background: #02133E;
                color: white;
                border-radius: 5px;
                font-size: 15px
            "
        >
            {{
                $store.state.language === "en"
                    ? "Today"
                    : "ယနေ့"
            }}
                <span class="text-success">&nbsp;{{ drawDate }}&nbsp;</span>
            {{
                $store.state.language === "en"
                    ? "Matches"
                    : "ပွဲများ"
            }}
            <i
                class="fas fa-long-arrow-alt-up"
                style="color: red"
                v-if="isToday"
                @click="changeToday"
            ></i>
            <i
                class="fas fa-long-arrow-alt-down"
                style="color: red"
                v-if="!isToday"
                @click="changeToday"
            ></i>
        </h1>

        <div class="d-flex my-3 justify-content-end" v-if="matches.length > 0">
          <div class="col-6 d-flex justify-content-center" v-if="filteredMatches.length > 0">
            <i class="fas fa-redo my-auto mr-2 text-lg" @click="resetFilter()"></i>
            <div class="text-uppercase text-danger" @click="resetFilter()">
                {{
                    $store.state.language === "en"
                        ? "Reset Filter"
                        : "ပွဲစဉ်များအားလုံး။"
                }}
            </div>
          </div>
          <div class="col-6 d-flex justify-content-center">
            <i class="fas fa-filter my-auto mr-2 text-lg" @click="filterModalBox()"></i>
            <div class="text-uppercase" @click="filterModalBox()">
                {{
                    $store.state.language === "en"
                        ? "Filter By League"
                        : "လိဒ်ဖြင့် စစ်ထုတ်ခြင်း။"
                }}
            </div>
          </div>
        </div>


        <div class="text-center align-items-center mt-5 pt-5" v-if="matches.length === 0 && isLoading">
            <Loading
                color="#02133E"
                size="24"
                class="px-4 py-0 m-auto pb-2 pt-5"
            />
            <span class="text-danger font-weight-bold">
                {{
                    $store.state.language === "en"
                        ? "Please wait. "
                        : "ဘောလုံးပွဲစဉ်များအား ရယူနေပါသည်။ "
                }}
            <br>
                {{
                    $store.state.language === "en"
                        ? "Football matches are fetching! "
                        : "ခေတ္တစောင့်ဆိုင်းပေးပါ။"
                }}
            </span>
        </div>
        <!-- <div
            v-for="(league, leagueIndex) in leagues"
            :key="leagueIndex"
            v-show="isToday && isLeagueHasMatches(league.fbmatches)"
            class="card"
        > -->
            <!-- <div>
                <h3 class="ml-2 pl-2 mt-2">{{ league.name }}</h3>
            </div> -->
            <div class="p-1 mb-5 pb-2" v-if="isToday && !isLoading">
                <FootballTeamCard
                    v-for="(match, index) in filteredMatches.length > 0 ? filteredMatches : matches"
                    :key="index"
                    :match="match"
                    :on-my-event="myEventSource"
                    v-show="match.league_id !== 315"
                />
            </div>
        <!-- </div> -->
        <div class="col-lg-4 col-md-6 px-2">
            <div class="row">
                <!-- === only amount === -->
                <div class="col-md-4 cart-voucher">
                    <div
                        class="modal fade"
                        id="mobile-voucher"
                        tabindex="-1"
                        role="dialog"
                        aria-labelledby="modal-form"
                        aria-hidden="true"
                    >
                        <div
                            class="
                                modal-dialog
                                modal-
                                modal-dialog-centered
                                modal-sm
                            "
                            role="document"
                        >
                            <div class="modal-content">
                                <div
                                    class="text-center modal-cross"
                                    @click="closeModal"
                                >
                                    X
                                </div>
                                <div class="modal-body p-0">
                                    <FBPreviewVoucher
                                        v-if="isMobile"
                                        @remove="removeHistory"
                                        type="maung"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- === only amount === -->
        <div class="col-md-4">
            <div
                class="modal fade"
                id="amount"
                tabindex="-1"
                role="dialog"
                aria-labelledby="modal-form"
                aria-hidden="true"
            >
                <div
                    class="modal-dialog modal- modal-dialog-centered modal-lg"
                    role="document"
                >
                    <div class="modal-content">
                        <div class="modal-body">
                            <div class="card border-0 mb-0">
                                <div class="card-body px-lg-5 py-lg-5">
                                    <div class="form-group mb-3">
                                        {{
                                            $store.state.language === "en"
                                                ? "Enter Amount"
                                                : "ငွေပမာဏထည့်ပါ"
                                        }}
                                        <div
                                            class="
                                                mt-2
                                                input-group
                                                input-group-merge
                                                input-group-alternative
                                            "
                                        >
                                            <div class="input-group-prepend">
                                                <span class="input-group-text"
                                                    ><i
                                                        class="
                                                            ni ni-money-coins
                                                        "
                                                    ></i
                                                ></span>
                                            </div>
                                            <input
                                                class="form-control"
                                                type="number"
                                                v-model="amount"
                                            />
                                        </div>
                                        <span class="text-danger">{{
                                            errors.amount
                                        }}</span>
                                    </div>
                                    <div class="text-center">
                                        <button
                                            type="button"
                                            class="btn btn-primary"
                                            @click="setToPreviewVoucher()"
                                        >
                                            {{
                                                $store.state.language === "en"
                                                    ? "Continue"
                                                    : "ဆက်သွားမည်"
                                            }}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="bet-container">
                <button class="btn btn-primary" @click="showModalBox"  style="background-color: #F68E56; border: none; color: black">
                    {{
                        $store.state.language === "en"
                            ? "Bet"
                            : "လောင်းမည်"
                    }}
                </button>
            </div> -->
        </div>

        <!-- === leagues filter === -->
        <div class="col-md-4">
            <div
                class="modal fade"
                id="league-box"
                tabindex="-1"
                role="dialog"
                aria-labelledby="modal-form"
                aria-hidden="true"
            >
                <div
                    class="modal-dialog modal- modal-dialog-centered modal-lg"
                    role="document"
                >
                    <div class="modal-content">
                        <div class="modal-body">
                            <div class="card border-0 py-3 px-3">
                              <h6 class="text-success mb-2">
                                {{
                                    $store.state.language === "en"
                                        ? "Please select leagues to filter!"
                                        : "ပွဲစဉ်များ စစ်ထုတ်ရန် လိဒ် တစ်ခုခုရွေးချယ်ပေးပါ။"
                                }}
                                </h6>
                                <div class="mb-4">
                                    <van-checkbox-group v-model="checkedLeagues" shape="square">
                                      <van-checkbox :name="league" class="my-2" v-for="(league, index) in currentLeagues" :key="index">{{league}}</van-checkbox>
                                    </van-checkbox-group>
                                </div>
                                <button
                                        type="button"
                                        class="btn btn-success mt-4"
                                        @click="closeFilterModal()"
                                    >
                                    DONE
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="bet-container">
                <button class="custom-btn" @click="showModalBox"> 
                   {{
                        $store.state.language === "en"
                            ? "Bet"
                            : "လောင်းမည်"
                    }}
                </button>
            </div> -->
        </div>

    </div>
    <!-- <div class="text-center align-items-center mt-5 pt-5" v-else>
            <span class="text-danger font-weight-bold">
                {{
                    this.$store.state.language === 'en'
                        ? 'Football Kyay not open yet.'
                        : 'ဘောလုံးကြေးမဖွင့်သေးပါ။'
                }}
        </span>
    </div> -->
</template>
<script>
import { Loading, Dialog, Sticky } from 'vant';
import { mapMutations, mapGetters, mapActions } from 'vuex';
import { newEventSource } from 'vue-parent-emit';
import FootballTeamCard from './components/FootballTeamCard.vue';
import FBPreviewVoucher from './components/FBPreviewVoucher.vue';

export default {
  components: { FootballTeamCard, FBPreviewVoucher, Loading, Sticky },
  data() {
    return {
      myEventSource: newEventSource(),
      isGoalPor: false,
      matches: [],
      leagues: [],
      historyLeagues: [],
      isMobile: false,
      amount: null,
      football: {},
      errors: {},
      isHistory: false,
      isToday: true,
      isLoading: false,
      checkedLeagues: [],
      currentLeagues: [],
      filteredMatches: [],
      numberAmount: null,
    };
  },
  computed: {
    ...mapGetters(['getFBOrder']),
    drawDate() {
      return this.matches[0]?.draw_date;
    },
  },
   watch:{
    checkedLeagues(){
      this.filteredMatches = this.matches.filter(match => {
        const isExist = this.checkedLeagues.filter(league => league === match.league)
        if(isExist.length > 0) return true;
        else false;
      })
    }
  },
  methods: {
    ...mapActions(['fetchUser']),
    closeFilterModal(){
      $('#league-box').modal('hide');
    },
    resetFilter(){
      this.checkedLeagues = [];
    },
    filterModalBox() {
      $('#league-box').modal('show');
    },
    isLeagueHasMatches(matches) {
      let matchesTotal = 0;

      for (let i = 0; i < matches.length; i += 1) {
        let isShow = true;
        // if (nowTime.getHours() >= 12) {
        //   if (matches[i].close_time.A === 'PM') {
        //     matchTime.setHours(
        //       parseInt(matches[i].close_time.hh, 10) + 12,
        //       parseInt(matches[i].close_time.mm, 10) - 5,
        //       0,
        //     );
        //     isShow = matchTime > nowTime;
        //   }
        // } else {
        //   if (matches[i].close_time.A === 'AM') {
        //     matchTime.setHours(
        //       parseInt(
        //         matches[i].close_time.hh === '12'
        //           ? 0
        //           : matches[i].close_time.hh,
        //         10,
        //       ),
        //       parseInt(matches[i].close_time.mm, 10) - 5,
        //       0,
        //     );
        //   } else {
        //     isShow = false;
        //   }
        //   isShow = matchTime > nowTime;
        // }
        const matchTime = new Date(this.match.start_time);
        const nowTime = new Date();
        isShow = matchTime > nowTime;
        if (isShow) matchesTotal += 1;
      }
      if (matchesTotal > 0) return true;
      return false;
    },
    sendEvent() {
      this.myEventSource.emit();
    },
    changeHistory() {
      this.isHistory = !this.isHistory;
    },
    changeToday() {
      this.isToday = !this.isToday;
    },
    ...mapMutations(['updateFBOrder', 'setWholeFBMatches', 'setBetAmount']),
    closeModal() {
      $('#mobile-voucher').modal('hide');
    },
    showModalBox() {
      $('#amount').modal('show');
    },
    removeHistory() {
      this.setWholeFBMatches([]);
      this.setBetAmount(null);
      this.sendEvent();
    },
    setToPreviewVoucher() {
      this.errors = {};
      if (!this.amount) {
        this.errors.amount = this.$store.state.language === 'en'
          ? 'Please enter amount'
          : 'ငွေပမာဏ ထည့်ပေးပါ။';
        return false;
      }
      if (this.amount < this.football.min_maung_fees) {
        this.errors.amount = this.$store.state.language === 'en'
          ? `Please enter amount more than ${this.football.min_maung_fees}`
          : `${this.football.min_maung_fees} ထက်ပိုထည့်ပေးပါ။`;
        return false;
      }
      if (this.amount > this.football.max_maung_fees) {
        this.errors.amount = this.$store.state.language === 'en'
          ? `Please enter amount less than ${this.football.max_maung_fees}`
          : `${this.football.max_maung_fees} အောက်ထည့်ပေးပါ။`;
        return false;
      }
      const matches = this.getFBOrder.fb_matches;
      this.setBetAmount(this.amount);
      for (let i = 0; i < matches.length; i += 1) {
        matches[i].bet_amount = this.amount;
      }
      this.setWholeFBMatches(matches);
      $('#amount').modal('hide');
      this.openModalVoucher();
      return true;
    },
    openModalVoucher() {
      if (this.$store.state.fb_order.fb_matches.length === 0) {
        const dataError = this.$store.state.language === 'en'
          ? 'Please select some teams!'
          : 'အသင်းတစ်ချို့ ရွေးပေးပါ။';
        this.dialog(dataError);
      } else {
        const matches = this.getFBOrder.fb_matches;
        let isBetAmount = true;
        for (let index = 0; index < this.getFBOrder.fb_matches.length; index += 1) {
          const fbMatch = this.getFBOrder.fb_matches[index];
          if (!fbMatch.bet_amount) {
            isBetAmount = false;
          }
        }
        if (!isBetAmount) {
          $('#amount').modal('show');
        } else {
          $('#mobile-voucher').modal('show');
        }
      }
    },
    dialog(text) {
      Dialog.alert({
        message: text,
        confirmButtonText:
                    this.$store.state.language === 'en'
                      ? 'Go Back'
                      : 'နောက်သို့ပြန်သွားမည်။',
      }).then(() => {
        // on close
      });
    },
    async fetchMatches() {
      try {
        const res = await axios.get('fb-matches', {
          params: {
            isFromClient: true,
            type: 'maung',
          },
        });
        this.matches = res.data.data;
      } catch (error) {
        console.log(error);
      }
    },
    async fetchLeagues() {
      try {
        this.isLoading = true;
        const date = new Date();
        // const res = await axios.get('/leagues-fb-matches');
        await this.fetchMatches();
        this.isLoading = false;
        const historyRes = await axios.get(
          '/leagues-fb-matches-history',
        );
        this.historyLeagues = historyRes.data.data;
        for (let index = 0; index < this.matches.length; index += 1) {
          // league filtering
          const match = this.matches[index];
          const isLeague = this.currentLeagues.filter(league => league === match.league)
          if(isLeague.length === 0){
            this.currentLeagues.push(match.league)
          }

          if (this.matches[index].kyayed_team === this.matches[index].first_team) {
            this.matches[index].kyayed_team = this.$store.state.language === 'en'
              ? this.matches[index].first_team_en
              : this.matches[index].first_team;
          } else if (this.matches[index].kyayed_team === this.matches[index].second_team) {
            this.matches[index].kyayed_team = this.$store.state.language === 'en'
              ? this.matches[index].second_team_en
              : this.matches[index].second_team;
          }

          this.matches[index].first_team = this.$store.state.language === 'en'
            ? this.matches[index].first_team_en
            : this.matches[index].first_team;

          this.matches[index].second_team = this.$store.state.language === 'en'
            ? this.matches[index].second_team_en
            : this.matches[index].second_team;
        }
      } catch (error) {
        console.log(error);
      }
    },

    async fetchFootball() {
      try {
        const res = await axios.get('/football');
        this.football = res.data.data[0];
        this.checkIsKyayOpen();
      } catch (error) {
        console.log(error);
      }
    },
    ready() {
      const channel = this.$pusher.subscribe('football-channel');
      channel.bind('football-matches', async (data) => {
        if (data.callApi) this.fetchLeagues();
      });
    },
    checkIsKyayOpen() {
      if (this.football && !this.football.is_kyay_open) {
        const text = this.$store.state.language === 'en'
          ? 'Football Kyay not open yet.'
          : 'ဘောလုံးကြေးမဖွင့်သေးပါ။';
        Dialog.alert({
          message: text,
          confirmButtonText:
                    this.$store.state.language === 'en'
                      ? 'Go Back'
                      : 'နောက်သို့ပြန်သွားမည်။',
        }).then(() => {
          // on close
          this.$router.push('/home');
        });
      }
    },
  },
  destroyed() {
    this.setBetAmount(null);
    this.setWholeFBMatches([]);
    this.$pusher.unsubscribe('football-channel');
  },
  unmount() {
    this.$pusher.unsubscribe('football-channel');
    this.updateFBOrder({ type, value: null });
  },
  async created() {
    const authUser = await this.fetchUser();
    if (!authUser) {
      this.$router.push('/auth/login');
    }
    if (authUser && !authUser.is_verified) {
      this.$router.push('/auth/verify');
    }
    this.updateFBOrder({ type: 'type', value: 'maung' });
    // this.fetchMatches();
    this.fetchLeagues();
    this.fetchFootball();
    // this.checkIsKyayOpen();

    this.ready();
    if (window.innerWidth < 768) {
      this.isMobile = true;
    }
  },
};
</script>
<style scoped>
.bet-container {
    position: fixed;
    bottom: 0;
    margin-bottom: 5vh;
    width: 300px;
    right: 0;
    left: 0;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0.25em;
    color: white;
    z-index: 100;
    visibility: visible;
    opacity: 1;
}
.modal-cross {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    padding-top: 3px;
    margin-left: auto;
    color: white;
    cursor: pointer;
    margin-right: -9px;
    margin-top: -9px;
    background: rgb(228, 87, 87);
}
@media (max-width: 768px) {
  .bet-container {
    /* width: 120px;  */
    background: transparent !important;
  }
}

.bet-footer {
        position: fixed;
        bottom: 0;
        width: 100%;
        height: auto;
        /* border-top: 2px solid black; */
    }
</style>
